import { useState, useRef, useEffect } from "react";
import butcoin_text from "../images/icons/butcoin_text.png";
import down_icon from "../images/icons/down.png";
import butcoin_icon from "../images/icons/butcoin.png";
import tick_icon from "../images/icons/tick.png";
import { useModal, Modal_Provider } from "../modals/modal_management";
import { currencies } from "../data/cryptos";
import config from "../configs/config.json";

import { useAccount, useConnect, useNetwork } from 'wagmi';
// import { useWeb3Modal } from '@web3modal/react';
import { switchChain, writeContract, fetchBalance } from '@wagmi/core';

import contract_addresses from "../configs/contract_addresses.json";
import butcoin_json from "../contract_abis/Butcoin.sol/Butcoin.json";
import { ethers } from "ethers"
import { is_valid_wallet_address, remove_param_without_reloading } from "../services/helper_functions";

import Clickable_Div from "./clickable_div";

import { preview_buy, preview_sell } from "../services/web3_getters";

import edit_icon from "../images/icons/edit.png";

import external_links from "../configs/external_links.json";

import { get_country, validate_country } from "../services/location";

import { get_active_address_by_id } from '../services/api'

import uniswap_icon from "../images/icons/uniswap.png"

import { wagmi_config } from "../configs/wagmi_config";
// import { useWeb3Modal } from '@web3modal/wagmi/react';

let null_address = "0x0000000000000000000000000000000000000000";

// https://polygon-rpc.com

function Swap_Widget_Content() {

    let [referral_address, set_referral_address] = useState(null_address);
    let [selected_crypto, set_selected_crypto] = useState(currencies[0]);

    let crypto_amount_input_ref = useRef();
    let butcoin_output_ref = useRef();

    let [swap_direction, set_swap_direction] = useState(0);
    let [agreed_tos, set_agreed_tos] = useState(false);
    let [tab, set_tab] = useState(0);

    useEffect(()=> {

        let url = new URL(window.location.href);
        let url_params = url.searchParams;


        (async function() {

            try {

                let country = await get_country();
                console.log({country});

                let is_geo_blocked = await validate_country(country);
                if (is_geo_blocked) {
                    open_modal("geo_block");
                }
                else if (url_params.get("referral")) {
                    let referral = url_params.get("referral");
                    // (async function() {
        
                        let referral_address = await get_active_address_by_id(referral);
        
                        if (is_valid_wallet_address(referral_address.address)) {
                            set_referral_address(referral_address.address);
                            open_modal("referral_warning");
                        }
                        else {
                            remove_param_without_reloading("referral");
                        }
        
                    // })();
                    
                }
                
            } catch (error) {
                console.log(error);
                open_modal("unknown_geo");
            }

        })();        

        crypto_amount_input_ref.current.value = 100;
        crypto_input_changed(0);

    },[]);

    useEffect(()=> {

        if (tab == 0) {
            crypto_amount_input_ref.current.value = 100;
        }
        else {
            crypto_amount_input_ref.current.value = 1000;
        }

        crypto_input_changed(0);

    },[tab]);

    const { isConnected, address, chain }  = useAccount();
    const { connect, connectors } = useConnect();
    // let { chain } = useNetwork();

    const injected_connector = connectors[0];
    // const web3Modal = useWeb3Modal();

    const { open_modal, close_modal } = useModal();

    async function handle_connect() {
        if (window.ethereum) {
            await connect({ connector: injected_connector, chainId: parseInt(config.chain_id) });
        } else {
            // await web3Modal.open();
            open_modal("mobile_connector");
        }
    }

    async function swap() {

        if (!agreed_tos) {
            alert("You must first read and agree to our terms and conditions before continuing.")
        }


        else if (chain.id == config.chain_id) {

            try {

                let amount = parseFloat(crypto_amount_input_ref.current.value);
                let min_out = parseFloat(butcoin_output_ref.current.value) * 0.95;
                min_out = ethers.parseEther(min_out.toString());

                // console.log({
                //     address: contract_addresses.Butcoin,
                //         abi: butcoin_json.abi,
                //         functionName: 'buy',
                //         args: [referral_address, min_out],
                //         value: ethers.parseEther(amount.toString())
                // }
                // )

                if (tab == 0) {
                    let { hash } = await writeContract(wagmi_config, {
                        address: contract_addresses.Butcoin,
                        abi: butcoin_json.abi,
                        functionName: 'buy',
                        args: [referral_address, min_out],
                        value: ethers.parseEther(amount.toString())
                    });
                }
                else {
                    let { hash } = await writeContract(wagmi_config, {
                        address: contract_addresses.Butcoin,
                        abi: butcoin_json.abi,
                        functionName: 'sell',
                        args: [ethers.parseEther(amount.toString()), referral_address, min_out]
                    });
                }

            } catch (error) {
                console.log(error);
                alert(error);
            }

        }
        else {
            // await switchNetwork({chainId: config.chain_id});
            await switchChain(wagmi_config, {chainId: parseInt(config.chain_id)});
        }


    }

    let timeout_ref = useState(null);

    async function crypto_input_changed(delay=2000) {

        if (timeout_ref.current !== null) {
            clearTimeout(timeout_ref.current);
        }

        timeout_ref.current = setTimeout(async function() {

            let amount = parseFloat(crypto_amount_input_ref.current.value);
            if (amount < 0) {
                amount = 0;
            }

            let set_amount = "";

            if (tab == 0) {
                set_amount = await preview_buy(amount.toString());
            }
            else {
                set_amount = await preview_sell(amount.toString());
            }

            butcoin_output_ref.current.value = parseFloat(set_amount).toFixed(2);

        }, delay);

    }

    let [crypto_focused, set_crypto_focused] = useState(false);


    return (
        <>
            {/* <div className="header_spacer"></div> */}
            {/* <img className="butcoin_text_logo" src={butcoin_text}/> */}
            <div className="central_container_box">
            <div className="cc_top_title green_background"><h2 className="handwritten">SWAP BUTTCHAIN</h2></div>
                <div className="sw_tab_container">
                    <Clickable_Div className={`light_hover sw_tab${tab==0?" sw_tab_selected":""}`} on_click={()=>set_tab(0)}>Buy</Clickable_Div>
                    <Clickable_Div className={`light_hover sw_tab${tab==1?" sw_tab_selected":""}`} on_click={()=>set_tab(1)}>Sell</Clickable_Div>
                </div>

                <div className="sw_main_section">

                    <div className="extra_seperator"></div>
                    <Clickable_Div className={`sw_swap_container ${crypto_focused?"sw_swap_container_focused":""}`} on_click={()=>{
                        crypto_amount_input_ref.current.focus();
                    }}>
                        <div className="sw_pre_label">You pay</div>
                        <input className="big_input_left" ref={crypto_amount_input_ref} onChange={crypto_input_changed} onFocus={()=>set_crypto_focused(true)} onBlur={()=>set_crypto_focused(false)} type="number"/>
                        <Clickable_Div className="small_currency_selector light_hover notranslate">
                            <img className="small_currency_icon" src={tab==0?selected_crypto.icon:butcoin_icon}/>
                            <div className="small_currency_label">{tab==0?selected_crypto.name:"Buttchain"}</div>
                        </Clickable_Div>
                    </Clickable_Div>
                    <div className="extra_seperator_small"></div>
                    <Clickable_Div className="sw_swap_container" on_click={()=>{
                        crypto_amount_input_ref.current.focus();
                        crypto_amount_input_ref.current.select();
                    }}>
                        <div className="sw_pre_label">You recieve</div>
                        <input className="big_input_left" ref={butcoin_output_ref} readOnly/>
                        <div className="small_currency_selector notranslate">
                            <img className="small_currency_icon" src={tab==1?selected_crypto.icon:butcoin_icon}/>
                            <div className="small_currency_label">{tab==1?selected_crypto.name:"Buttchain"}</div>
                        </div>
                    </Clickable_Div>

{/*                 
                    <div className="extra_seperator"></div>
                    <div className="sw_swap_container">
                        <div className="sw_pre_label">You pay</div>
                            <div className="edit_icon_container edit_icon_container_big">
                                <input className="sw_amount_input_container" ref={crypto_amount_input_ref} onChange={crypto_input_changed}/>
                                <Clickable_Div className="sw_edit_icon" on_click={()=>{
                                    crypto_amount_input_ref.current.focus();
                                    crypto_amount_input_ref.current.select();
                                }}>
                                    <img className="full_icon" src={edit_icon}/>
                                </Clickable_Div>
                            </div>
                            <Clickable_Div className="sw_currency_selector sw_currency_selector_small light_hover notranslate">
                                <img className="sw_currency_icon" src={tab==0?selected_crypto.icon:butcoin_icon}/>
                                <div className="sw_currency_label">{tab==0?selected_crypto.name:"Butcoin"}</div>
                            </Clickable_Div>
                    </div>
                    <div className="extra_seperator_small"></div>
                    <div className="sw_swap_container">
                        <div className="sw_pre_label">You recieve</div>
                        <div className="edit_icon_container edit_icon_container_big">
                            <input className="sw_amount_input_container sw_amount_input_container_no_edit" ref={butcoin_output_ref}/>
                        </div>
                        <div className="sw_currency_selector notranslate sw_currency_selector_small">
                            <img className="sw_currency_icon" src={tab==1?selected_crypto.icon:butcoin_icon}/>
                            <div className="sw_currency_label">{tab==1?selected_crypto.name:"Butcoin"}</div>
                        </div>
                    </div> */}

                    {
                        // window.ethereum ?
                        <>

                            <Clickable_Div className={`tick_button_hover sw_tos_box${agreed_tos?" sw_tos_box_ticked":""}`} on_click={()=>set_agreed_tos(!agreed_tos)}>
                                { agreed_tos && <img className="sw_tos_tick_icon" src={tick_icon}/> }
                            </Clickable_Div>
                            <div className="sw_tos_text">
                                I agree to <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.terms)}><u className="clickable_text">Terms of Use</u></Clickable_Div> and <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.privacy)}><u className="clickable_text">Privacy Policy</u></Clickable_Div>. I hereby also certify that I have read in full and understood the <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.disclaimer)}><u className="clickable_text">Risks Disclaimer</u></Clickable_Div> and I am not from any <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.blocked_countries)}><u className="clickable_text">Blocked Country</u></Clickable_Div>.</div>
                            
                            { !isConnected ?
                                <Clickable_Div className="sw_buy_button green_button_hover" on_click={handle_connect}>
                                    <b>Connect Wallet</b>
                                </Clickable_Div> :
                                <Clickable_Div className="sw_buy_button green_button_hover" on_click={()=>{
                                    swap();
                                }}>
                                    {
                                        selected_crypto.name == "Polygon" &&
                                        chain.id != config.chain_id ?
                                        <b>Switch Network</b> :
                                        <b>Swap</b>
                                    }
                                </Clickable_Div>
                            }
                        <center>
                            <br/>
                            <p className="small_text">Transaction Processed Via</p>
                        </center>
                        <img className="uni_img" src={uniswap_icon}/>
                    

                        </>
                        // :
                        // <>
                        //     <br/>
                        //     <p className="extra_text_thing">No ethereum wallet detected. Please visit our website from within the web browser located inside of your wallet app.</p>
                        // </>
                    }


                    
                </div>
            </div>
        </>
    );



}

function Swap_Widget() {
    return (
        <Modal_Provider>
            <Swap_Widget_Content/>
        </Modal_Provider>
    )
}

export default Swap_Widget;