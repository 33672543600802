
import { scroll_to_pannel, nudge } from "../services/navigation";
import Clickable_Div from "./clickable_div";

import send_funds_icon from "../images/illustrations/send_funds.png";
import select_currency_icon from "../images/illustrations/select_currency.png";
import connect_wallet_icon from "../images/illustrations/connect_wallet.png";
import external_links from "../configs/external_links.json";

function How_To_Buy() {

    return (
        // <div className="full_background outer_flex">
            // <img className="full_background_image" src={background_6}/>
            // <div className="container_outer">
                <div className="central_container_box forced_center">
                    <div className="cc_top_title orange_background"><h2 className="handwritten">HOW TO BUY</h2></div>
                    <div className="standard_box_inner">
                        <div className="standard_box_text">

                            <div className="feature_container">
                                <img className="feature_img" src={connect_wallet_icon}/>
                                <div className="feature_text">
                                    <h3>Connect Crypto Wallet</h3><br/>
                                    <p>Connect your Web3 Cryptocurrency Wallet to our website. If you do not have a wallet, here is a <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.guides)}><u className="clickable_text">guide to setting up a wallet</u></Clickable_Div> in a few short steps.</p>
                                </div>
                            </div>

                            {/* <div className="feature_container">
                                <img className="feature_img" src={select_currency_icon}/>
                                <div className="feature_text">
                                    <h3>Select Currency</h3><br/>
                                    <p>Select the cryptocurrency you would like to pay in or USD for credit card payments. We support a wide range of cryptocurrencies and payment methods.</p>
                                </div>
                            </div> */}

                            <div className="feature_container">
                                <img className="feature_img" src={send_funds_icon}/>
                                <div className="feature_text">
                                    <h3>Pay in Polygon</h3><br/>
                                    <p>Follow the instructions on screen to send Polygon to pay for your purchase. Make sure you have enough polygon matic in your wallet.</p>
                                </div>
                            </div>

                        </div>

                        <Clickable_Div className="colored_button orange_background orange_button_hover" on_click={()=>{scroll_to_pannel(0); nudge(0);}}>Buy Now</Clickable_Div>


                        <div className="standard_box_text">
                            "My grandson won't stop posting pics of his butt on the internet."<br/><br/>
                            — your grandma (visibly confused)
                        </div>

                    </div>
                </div>
            // </div>
        // </div>
    )

}

export default How_To_Buy;