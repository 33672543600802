import { useState, useRef } from "react";
import background_2 from "../images/backgrounds/background_2.png";
import proof_of_butt from "../images/illustrations/proof_of_butt.png";
import connect_wallet_icon from "../images/illustrations/connect_wallet.png";
import generate_link_icon from "../images/illustrations/generate_link.png";
import share_and_earn_icon from "../images/illustrations/share_and_earn.png";
import copy_icon from "../images/icons/copy.png"
import { scroll_to_pannel, nudge } from "../services/navigation";
import { useAccount, useConnect } from 'wagmi';
// import { useWeb3Modal } from '@web3modal/react';
import { shorten_crypto_string } from "../services/helper_functions";
import Clickable_Div from "./clickable_div";
import { useModal, Modal_Provider } from "../modals/modal_management";
import { register_referral } from "../services/api";
import external_links from "../configs/external_links.json";
import tick_icon from "../images/icons/tick.png"

import { wagmi_config } from "../configs/wagmi_config";

function Proof_Of_Butt_Content() {

    let link_input = useRef();
    const { open_modal, close_modal } = useModal();


    let [ref_link, set_ref_link] = useState(null);
    let [agreed_tos, set_agreed_tos] = useState(false);

    const { isConnected, address, chain }  = useAccount();
    const { connect, connectors } = useConnect();

    const injected_connector = connectors[0];

    async function connect_wallet() {
        if (window.ethereum) {
            await connect({ connector: injected_connector });
        } else {
            open_modal("mobile_connector");
        }
    }


    function track_referrals() {
        open_modal("referrals");
    }

    async function generate_referral_link() {
        if (!agreed_tos) {
            alert("You must first read and agree to our terms of use in order to generate a referral link");
            return;
        }
        let { id } = await register_referral(address);
        set_ref_link(`https://buttchain.co/?referral=${id}`)
    }

    return (
        
        // <div className="full_background outer_flex">
            // <img className="full_background_image" src={background_2}/>
            // <div className="container_outer">
                <div className="central_container_box forced_center">
                    <div className="cc_top_title purple_background"><h2 className="handwritten">SHARE & EARN</h2></div>
                    <div className="standard_box_inner">
                        <div className="standard_box_text">
                            {/* <img className="standard_illustration" src={proof_of_butt}/>
                            <br/><br/>
                            Unlike traditional cryptocurrencies that use Proof of Work (PoW) or Proof of Stake (PoS), Butcoin operates on the innovative "Proof of Butt" mechanism. This playful twist not only serves as a memorable marketing hook but also encourages community participation in a humorous way. It’s so dumb it could actually work!

                            <br/><br/> */}

                            {/* <div className="feature_container">
                                <img className="feature_img" src={connect_wallet_icon}/>
                                <div className="feature_text">
                                    <h3>Connect Wallet</h3>
                                    <p>Connect your Web3 Wallet. We use your wallet address to generate a unique link where you can automatically recieve your referral fees.</p><br/>
                                    { !isConnected ?
                                        <div className="inline_connect_button light_hover" onClick={handle_connect}>Connect Wallet</div> :
                                        <>
                                            <p>Connected with: <u>{shorten_crypto_string(address)}</u></p>
                                        </>
                                    }
                                    
                                </div>
                            </div> */}

                            <div className="feature_container">
                                <img className="feature_img" src={generate_link_icon}/>
                                <div className="feature_text">
                                    <h3>Generate Unique Link</h3>
                                    <br/>
                                    <p>Connect your crypto wallet and copy your unique referral link below. You will automatically earn a 5% referral fee in ButtChain tokens for any purchase going through your unique link.</p><br/>
                                    { !isConnected ?
                                        <Clickable_Div className="inline_connect_button light_hover" on_click={connect_wallet}>Connect Wallet</Clickable_Div> :
                                        <>
                                            {
                                                ref_link != null ?
                                                <>
                                                    <input className="inline_link_copy" value={ref_link} ref={link_input}/>
                                                    <Clickable_Div className="copy_icon_container light_hover" on_click={()=>{
                                                        navigator.clipboard.writeText(ref_link)
                                                        link_input.current.select();
                                                    }}>
                                                        <img className="copy_icon_inner" src={copy_icon}/>
                                                    </Clickable_Div>
                                                </> :
                                                <>
                                                    <Clickable_Div className="inline_connect_button light_hover" on_click={generate_referral_link}>Generate Referral Link</Clickable_Div>
                                                    <br/><br/>
                                                    <Clickable_Div on_click={track_referrals} className="inline_connect_button light_hover">
                                                        Track Referrals
                                                    </Clickable_Div>
                                                    <br/>
                                                    <Clickable_Div className={`tick_button_hover sw_tos_box_lean sw_tos_box${agreed_tos?" sw_tos_box_ticked":""}`} on_click={()=>set_agreed_tos(!agreed_tos)}>
                                                        { agreed_tos && <img className="sw_tos_tick_icon" src={tick_icon}/> }
                                                    </Clickable_Div>
                                                    <div className="sw_tos_text sw_tos_text_lean" style={{textAlign: "left"}}>
                                                        I agree to <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.terms)}><u className="clickable_text">Terms of Use</u></Clickable_Div> and <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.privacy)}><u className="clickable_text">Privacy Policy</u></Clickable_Div>. I hereby also certify that I have read in full and understood the <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.disclaimer)}><u className="clickable_text">Risks Disclaimer</u></Clickable_Div>, <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.referral_guidelines)}><u className="clickable_text">Referral Guidelines</u></Clickable_Div>, and I am not from any <Clickable_Div className="inline_content" on_click={()=>window.open(external_links.blocked_countries)}><u className="clickable_text">Blocked Country</u></Clickable_Div>.
                                                    </div>
                                                </>
                                            }
                                            
                                        </>
                                    }
                                    
                                </div>
                            </div>
{/* 
                            <div className="feature_container">
                                <img className="feature_img" src={share_and_earn_icon}/>
                                <div className="feature_text">
                                    <h3>Share and Earn</h3>
                                    <br/>
                                    <p>Post your unique referral link on Social Media with a picture of your butt (pants required).</p><br/>
                                    <p>Worried about doxxing your ass? Post a picture of any kind of butt. Corgi butt, hand drawn cartoon drawing of a butt, or even a famous butt.</p><br/>
                                    <p>You can track your referrals with the button below:</p><br/>
                                    <Clickable_Div on_click={track_referrals} className="inline_connect_button light_hover">
                                        Track Referrals
                                    </Clickable_Div>
                                    
                                </div>
                            </div> */}
                        </div>



                        {/* <div className="standard_button_container">
                            <div className="standard_button_gradient"></div>
                            <div className="standard_button_inner"><b>Buy Now</b></div>
                        </div>

                        <div className="standard_box_text">
                            "This is so crazy it might actually work"<br/>
                            -- you (probably)
                        </div> */}
                    </div>
                    
                </div>
            // </div>

        // </div>
    )

}



function Proof_Of_Butt() {
    return (
        <Modal_Provider>
            <Proof_Of_Butt_Content/>
        </Modal_Provider>
    )
}

export default Proof_Of_Butt;