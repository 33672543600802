import { useState, useRef } from "react";

import plus_icon from "../images/icons/plus.png";
import Clickable_Div from "./clickable_div";
import { useAccount, useConnect } from 'wagmi';
// import { useWeb3Modal } from '@web3modal/react';
import { signMessage } from '@wagmi/core'
import { ethers } from "ethers";
import { seppuku } from "../services/api";
import external_links from "../configs/external_links.json";
import contract_addresses from "../configs/contract_addresses.json"

// ---- Modals ----
import { useModal, Modal_Provider } from "../modals/modal_management";
import { wagmi_config } from "../configs/wagmi_config";

function FAQ_Content() {

    // ---- Modal Triggers ----
    const { open_modal, close_modal } = useModal();

    // ---- Wallet Connection ----
    const { isConnected, address, chain }  = useAccount();
    const { connect, connectors } = useConnect();

    const injected_connector = connectors[0];

    async function connect_wallet() {
        if (window.ethereum) {
            await connect({ connector: injected_connector });
        } else {
            open_modal("mobile_connector");
        }
    }

    async function commit_seppuku() {
        try {
            
            let result = await signMessage(wagmi_config, { message: 'seppuku' });
            let signature = ethers.Signature.from(result);
            let { v, r, s } = signature;
            let addi = ethers.verifyMessage('seppuku', result);
            await seppuku(address, v, r, s);

        } catch (error) {}
    }

    let [selected_index, set_selected_index] = useState(-1);

    let faq_items = [
        // {
        //     question: "What is the price of ButtChain at presale?",
        //     answer: <p>
        //         During the presale 1 $BUTT is priced at 0.1 Polygon Matic. The price of $BUTT in other cryptocurrencies and currencies may therefore fluctuate depending on their price relative to Polygon Matic.
        //     </p>
        // },
        // {
        //     question: "When will the ButtChain presale end?",
        //     answer: <p>
        //         The ButtChain presale will end automatically on December 31st 2024 or sooner if 250,000,000 ButtChain tokens are sold at presale. 
        //         <br/><br/>
        //         After the presale is over, you will be able to trade your ButtChain for Matic on our website (via Uniswap) and directly on Uniswap.
        //     </p>
        // },
        {
            question: "I purchased ButtChain but I cannot see it in my wallet",
            answer: <p>
                After you purchase ButtChain tokens, you need to import our smart contract details into your Web3 crypto wallet.<br/><br/>
                <table className="standard_table notranslate">
                    <tr>
                        <td>Blockchain</td>
                        <td>Polygon (MATIC)</td>
                    </tr>
                    <tr>
                        <td>Contract Address</td>
                        <td>{contract_addresses.Butcoin}</td>
                    </tr>
                    <tr>
                        <td>Token Symbol</td>
                        <td>BUTT</td>
                    </tr>
                    <tr>
                        <td>Token Decimals</td>
                        <td>18</td>
                    </tr>
                </table>
                <br/>
                You can find more precise instructions for your specific <Clickable_Div className="inline_content clickable_text" on_click={()=>window.open(external_links.import_wallet)}><u>wallet here</u></Clickable_Div>.
            </p>
        },
        {
            question: "Someone claiming to be from the ButtChain team has contacted me out of the blue",
            answer: <p>
                The ButtChain team will never contact you out of the blue. Please only contact support@buttchain.co for support.
            </p>
        },
        // {
        //     question: "When can I sell my ButtChain?",
        //     answer: <p>
        //         After the presale is over, you will be able to trade your ButtChain for Matic on our website (via Uniswap) and directly on Uniswap.
        //     </p>
        // },
        // {
        //     question: "When can I transfer my ButtChain?",
        //     answer: <p>
        //         After the presale is over, you will be able to transfer your ButtChain.
        //     </p>
        // },
        {
            question: "Are there any transfer or trading taxes on ButtChain?",
            answer: <p>
                There are no taxes on regular transfers of ButtChain. However, there is a 10% tax on all swaps on our liquidity pool. The tax is used to grow the liquidity of ButtChain on Uniswap, pay referral fees, and pay a royalty to the ButtChain creators. Below is a detailed breakdown: 
                <br/><br/>
                <h3>Buy Tax</h3><br/>
                <table className="standard_table notranslate" style={{textAlign: "center"}}>
                    <tr>
                        <td>Referral</td>
                        <td>5%</td>
                    </tr>
                    <tr>
                        <td>Auto Liquidity</td>
                        <td>2.5%</td>
                    </tr>
                    <tr>
                        <td>Burn</td>
                        <td>2.5%</td>
                    </tr>
                </table>
                <br/>
                <i>Please note that if there is no referral, the coins corresponding to the 5% Referral share of the Buy tax  will be burnt instead.</i>
                <br/><br/>
                <h3>Sell Tax</h3><br/>
                <table className="standard_table notranslate" style={{textAlign: "center"}}>
                    <tr>
                        <td>Creator Royalty</td>
                        <td>5%</td>
                    </tr>
                    <tr>
                        <td>Auto Liquidity</td>
                        <td>5%</td>
                    </tr>
                </table>

            </p>
        },
        {
            question: "How is ButtChain's liquidity managed?",
            answer: <p>
                ButtChain's liquidity is seamlessly managed by automated smart contracts.
                <br/><br/>
                During the presale, 20% of each purchase's value in crypto will be automatically allocated into the Uniswap liquidity pool. Concurrently, an equivalent amount of ButtChain is minted and added to this pool.
                <br/><br/>
                Post-presale, transactions involving this liquidity pool are taxed. A portion of the value of the tax is then automatically added to the pool, further raising its liquidity.
            </p>
        },
        {
            question: "My wallet has been compromised, what should I do?",
            answer: <p>
                ButtChain is a decentralized protocol. We do not have any way to recover lost or stolen funds.
                <br/><br/>
                As such, if your wallet has been compromised, you may burn your ButtChain tokens to prevent scammers from dumping them when the presale ends.
                <br/><br/>
                For this purpose, we have created a meta-transaction function (which does not require gas) to burn all your wallet ButtChain. We call this function Seppuku, after the Japanese ritual of suicide performed by the Samurai.
                <br/><br/>
                It's the honorable thing to do:
                <br/><br/>
                { !isConnected ?
                    <Clickable_Div className="inline_connect_button light_hover" on_click={connect_wallet}>Connect Wallet</Clickable_Div> :
                    <Clickable_Div className="inline_connect_button light_hover" on_click={commit_seppuku}>Burn My ButtChain</Clickable_Div>
                }
                <br/><br/>
                <a style={{color: "red"}}>Warning: Burning tokens is irreversible.</a>
            </p>
        },
        {
            question: "Is ButtChain a good and safe investment?",
            answer: <p>
                If you're seriously wondering if you should invest your life savings into a butt themed parody meme coin, please seek mental help.
                <br/><br/>
                Like all cryptocurrencies ButtChain is extremely risky. ButtChain is just for fun and with all risky crypto coins you should only spend sums of money you can afford to lose.
                <br/><br/>
                We have a more detailed analysis of some of the risks related to purchasing ButtChain <Clickable_Div className="inline_content clickable_text" on_click={()=>window.open(external_links.disclaimer)}><u>available here</u></Clickable_Div>.
            </p>
        },
        {
            question: "I can't find what I'm looking for?",
            answer: <p>
                Don't sweat it! Drop us an email at support@buttchain.co and we'll get back to you as soon as we can.
            </p>
        }
    ]


    return (
        // <div className="full_background outer_flex">
            // <img className="full_background_image" src={background_7}/>
            // <div className="container_outer">
                <div className="central_container_box forced_center">
                    <div className="cc_top_title orange_background"><h2 className="handwritten">FAQ</h2></div>
                    <div className="standard_box_inner">
                        <div className="standard_box_text">
                            { faq_items.map((item, index) => (
                                <Clickable_Div className={`faq_item ${index==selected_index?"empty_hover":"light_hover"}`} on_click={()=>{
                                    if (index!=selected_index) {
                                        set_selected_index(index);
                                    }
                                }}>
                                    <Clickable_Div className="faq_title "  on_click={()=>set_selected_index(index==selected_index?-1:index)}>
                                        <b>{ item.question }</b>
                                    </Clickable_Div>
                                    <div className={`faq_answer${index==selected_index?" faq_answer_selected":""}`}>
                                        { item.answer }
                                    </div>
                                    <Clickable_Div on_click={()=>set_selected_index(index==selected_index?-1:index)}>
                                        <img className={`faq_icon${index==selected_index?" faq_icon_selected":""}`} src={plus_icon}/>
                                    </Clickable_Div>
                                </Clickable_Div>
                            )) }
                        </div>
                    </div>
                </div>
            // </div>
        // </div>
    )

}

function FAQ() {
    return (
        <Modal_Provider>
            <FAQ_Content/>
        </Modal_Provider>
    )
}
export default FAQ;