import { useState, useEffect, useRef } from "react";
import Clickable_Div from "../components/clickable_div";

// ---- Icons ----
import cross_icon from "../images/icons/cross.png";
import right_icon from "../images/icons/right.png";
import metamask_icon from "../images/icons/metamask.png";
import trustwallet_icon from "../images/icons/trustwallet.png";
import coinbase_icon from "../images/icons/coinbase.png";
import okx_icon from "../images/icons/okx.png";
import wallet_connect_icon from "../images/icons/wallet_connect.png";

// ---- WAGMI ----
import { useWeb3Modal } from '@web3modal/wagmi/react';

function Mobile_Connector({ close_modal, parameters }) {

    function open_wallet_deep_link(wallet_name) {

        let url = new URL(window.location.href);
        let url_params = url.searchParams;

        let site = "https://buttchain.co/?try_connect=true"

        if (parameters && parameters.extra) {
            site += parameters.extra;
        }

        if (url_params.get("referral")) {
            let referral = url_params.get("referral");
            site += `&referral=${referral}`;
        }

        if (url_params.get("source")) {
            let source = url_params.get("source");
            site += `&source=${source}`;
        }

        console.log(site);

        if (wallet_name == "metamask") {
            window.open(`https://metamask.app.link/dapp/${site.replace("https://","")}`)
        }
        if (wallet_name == "okx") {
            window.open("https://www.okx.com/download?deeplink=" + encodeURIComponent("okx://wallet/dapp/url?dappUrl=" + encodeURIComponent(site)));
        }
        if (wallet_name == "coinbase") {
            window.open(`https://go.cb-w.com/dapp?cb_url=${site}`);
        }
        if (wallet_name == "trustwallet") {
            window.open(`https://link.trustwallet.com/open_url?url=${site}`);
        }

    }

    const web3Modal = useWeb3Modal();

    async function wallet_connect() {
        close_modal();
        await web3Modal.open();
    }

    return (
        <>
            <img className="close_modal" src={cross_icon} onClick={close_modal}/>
            <div className="modal_title"><h3>Connect Wallet</h3></div>
            <h3>Connect Wallet</h3>
            <br/>
            <p>Select your wallet from the list below.</p>
            <Clickable_Div className="wallet_connect_button" on_click={()=>open_wallet_deep_link("metamask")}>
                <img className="wallet_connect_icon" src={metamask_icon}/>
                <div className="wallet_connect_lbl">MetaMask</div>
                <img className="wallet_connect_right" src={right_icon}/>
            </Clickable_Div>
            <Clickable_Div className="wallet_connect_button" on_click={()=>open_wallet_deep_link("trustwallet")}>
                <img className="wallet_connect_icon" src={trustwallet_icon}/>
                <div className="wallet_connect_lbl">Trust Wallet</div>
                <img className="wallet_connect_right" src={right_icon}/>
            </Clickable_Div>
            <Clickable_Div className="wallet_connect_button" on_click={()=>open_wallet_deep_link("coinbase")}>
                <img className="wallet_connect_icon" src={coinbase_icon}/>
                <div className="wallet_connect_lbl">Coinbase Wallet</div>
                <img className="wallet_connect_right" src={right_icon}/>
            </Clickable_Div>
            <Clickable_Div className="wallet_connect_button" on_click={()=>open_wallet_deep_link("okx")}>
                <img className="wallet_connect_icon" src={okx_icon}/>
                <div className="wallet_connect_lbl">OKX Wallet</div>
                <img className="wallet_connect_right" src={right_icon}/>
            </Clickable_Div>
            <Clickable_Div className="wallet_connect_button" on_click={wallet_connect}>
                <img className="wallet_connect_icon" src={wallet_connect_icon}/>
                <div className="wallet_connect_lbl">Other Wallets</div>
                <img className="wallet_connect_right" src={right_icon}/>
            </Clickable_Div>
            <br/><br/>
            <p>Don't see your wallet? Navigate to buttchain.co in the browser inside of your wallet app.</p>

        </>
    )

}


export default Mobile_Connector;